var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flexmars" },
    [
      _c(
        "div",
        { staticClass: "claimbox" },
        [
          _vm._m(0),
          _c("el-input", {
            attrs: { placeholder: "填写物流单号", maxlength: "15" },
            model: {
              value: _vm.formdata.deliveryoid,
              callback: function($$v) {
                _vm.$set(_vm.formdata, "deliveryoid", $$v)
              },
              expression: "formdata.deliveryoid"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "bgbox" }),
      _c("div", { staticClass: "flex-between citysecion" }, [
        _c("div", { staticClass: "firstbox upbox" }, [_vm._v("起运地")]),
        _c("div", { on: { click: _vm.showPopup } }, [
          _vm._v(" " + _vm._s(_vm.preAddr_ || _vm.initCity.preAddr) + " "),
          _c("i", { staticClass: "el-icon-arrow-right" })
        ])
      ]),
      _c("div", { staticClass: "flex-between" }, [
        _c("div", { staticClass: "firstbox" }, [_vm._v("目的地")]),
        _c("div", [
          _vm._v(_vm._s(_vm.addressBox || _vm.GLOBAL + "北京 (万特) 办事处"))
        ])
      ]),
      _vm._m(1),
      _c("div", { staticClass: "flex-between" }, [
        _c("div", { staticClass: "firstbox" }, [_vm._v("货品种类")]),
        _c("div", [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formdata.selectbox,
                  expression: "formdata.selectbox"
                }
              ],
              staticClass: "typebox",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.formdata,
                    "selectbox",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "黄金,huangjin" } }, [
                _vm._v("黄金")
              ]),
              _c("option", { attrs: { value: "白银,baiyin" } }, [
                _vm._v("白银")
              ]),
              _c("option", { attrs: { value: "铂金,bojin" } }, [
                _vm._v("铂金")
              ]),
              _c("option", { attrs: { value: "钯金,bajin" } }, [
                _vm._v("钯金")
              ]),
              _c("option", { attrs: { value: "钻石,zhuanshi" } }, [
                _vm._v("钻石")
              ])
            ]
          ),
          _c("i", { staticClass: "el-icon-arrow-right" })
        ])
      ]),
      _c("div", { staticClass: "flex-between" }, [
        _c("div", { staticClass: "firstbox" }, [_vm._v("货品重量（克）")]),
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "inputboxhidden",
              attrs: { placeholder: "货品重量(克)", maxlength: "10" },
              model: {
                value: _vm.formdata.weight_input,
                callback: function($$v) {
                  _vm.$set(_vm.formdata, "weight_input", $$v)
                },
                expression: "formdata.weight_input"
              }
            })
          ],
          1
        )
      ]),
      _vm._m(2),
      _c("div", { staticClass: "flex-between" }, [
        _c("div", { staticClass: "firstbox" }, [_vm._v("投保额度（元）")]),
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "inputboxhidden",
              attrs: { placeholder: "投保额度(元)", maxlength: "8" },
              on: { input: _vm.getbaoxian },
              model: {
                value: _vm.formdata.money_input,
                callback: function($$v) {
                  _vm.$set(_vm.formdata, "money_input", $$v)
                },
                expression: "formdata.money_input"
              }
            })
          ],
          1
        )
      ]),
      _vm._m(3),
      _c("div", { staticClass: "flex first_flex" }, [
        _c("div", { staticClass: "firstbox_flex" }, [_vm._v("姓名")]),
        _c("div", [_vm._v(_vm._s(_vm.userdetails.realName))])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "firstbox_flex" }, [_vm._v("身份证号")]),
        _c("div", [_vm._v(_vm._s(_vm.userdetails.identificationCard))])
      ]),
      _c("div", { staticClass: "bgbox" }),
      _c(
        "span",
        { staticClass: "rulebox" },
        [
          _c("el-checkbox", {
            model: {
              value: _vm.checkboxbool,
              callback: function($$v) {
                _vm.checkboxbool = $$v
              },
              expression: "checkboxbool"
            }
          }),
          _vm._v(" 同意 "),
          _c(
            "b",
            {
              on: {
                click: function($event) {
                  return _vm.rulepage("InsuranceClause")
                }
              }
            },
            [_vm._v("《货运基本条例》")]
          ),
          _c(
            "b",
            {
              on: {
                click: function($event) {
                  return _vm.rulepage("claimeditHangkongxian")
                }
              }
            },
            [_vm._v("《附加航空险》")]
          ),
          _vm._v("和 "),
          _c(
            "b",
            {
              on: {
                click: function($event) {
                  return _vm.rulepage("claimeditdelivery")
                }
              }
            },
            [_vm._v("《附加邮包险》")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "flex-between bottombox" }, [
        _c("div", { staticClass: "baoxianfei" }, [
          _c("span", [_vm._v("保险费:")]),
          _c("b", [_c("i", [_vm._v("¥")]), _vm._v(_vm._s(_vm.bxfei) + ".00")])
        ]),
        _c(
          "div",
          {
            staticClass: "toubao",
            on: {
              click: function($event) {
                return _vm.toubaopost("nodata")
              }
            }
          },
          [_vm._v("立即投保")]
        )
      ]),
      _c(
        "div",
        [
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.show,
                callback: function($$v) {
                  _vm.show = $$v
                },
                expression: "show"
              }
            },
            [
              _c("van-area", {
                attrs: {
                  "area-list": _vm.areaList,
                  value: _vm.addrCode,
                  "cancel-button-text": " "
                },
                on: { confirm: _vm.confirmF }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-dialog",
        {
          staticClass: "toubaotip",
          attrs: {
            width: "75%",
            showConfirmButton: false,
            "show-close": "false"
          },
          model: {
            value: _vm.ceringModel,
            callback: function($$v) {
              _vm.ceringModel = $$v
            },
            expression: "ceringModel"
          }
        },
        [
          _c("div", {}, [
            _c("div", { staticClass: "toubao_title" }, [_vm._v("信息确认")]),
            _c("div", { staticClass: "centerbox_dialog" }, [
              _vm._v(
                " 投保信息即将提交至保险系统，一旦提交信息将无法修改和删除，请仔细核对各项信息，确认无误后再提交！ "
              )
            ]),
            _c("div", { staticClass: "flex-around xuzhifooter" }, [
              _c(
                "div",
                { staticClass: "leftbor", on: { click: _vm.closealert } },
                [_vm._v("重新核对")]
              ),
              _c(
                "div",
                {
                  staticClass: "redbox",
                  on: {
                    click: function($event) {
                      return _vm.toubaopost("realpost")
                    }
                  }
                },
                [_vm._v("确认无误")]
              )
            ])
          ])
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: { width: "75%" },
          model: {
            value: _vm.firstshow,
            callback: function($$v) {
              _vm.firstshow = $$v
            },
            expression: "firstshow"
          }
        },
        [
          _c("div", {}, [
            _c("div", { staticClass: "toubao_title" }, [_vm._v("投保须知")]),
            _c("div", { staticClass: "centerbox_dialog borderbottom" }, [
              _vm._v(
                " 为保证保单的有效性，请务必认真填写包括顺丰单号在内的各项保险信息并仔细核对。请勿对同一件货物重复投保，以避免您不必要的支出！ "
              )
            ])
          ])
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: {
            type: "alert",
            title: "",
            width: "80%",
            "show-cancel-button": ""
          },
          model: {
            value: _vm.showrule,
            callback: function($$v) {
              _vm.showrule = $$v
            },
            expression: "showrule"
          }
        },
        [
          _c("div", { staticClass: "opopbox" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.xieyineirong) } }, [
              _vm._v(_vm._s(_vm.xieyineirong))
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleclaim" }, [
      _c("b", { staticClass: "bimg" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/toubao_2.png"), alt: "" }
        })
      ]),
      _vm._v("顺丰单号 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "titlebox" }, [
      _c("img", { attrs: { src: "", alt: "" } }),
      _c("b", { staticClass: "bimg" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/toubao_4.png"), alt: "" }
        })
      ]),
      _vm._v("货品内容 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "titlebox" }, [
      _c("b", { staticClass: "bimg" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/toubao_3.png"), alt: "" }
        })
      ]),
      _vm._v("选择保额 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "titlebox" }, [
      _c("b", { staticClass: "bimg" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/toubao_1.png"), alt: "" }
        })
      ]),
      _vm._v("被保险人 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <!-- 投保信息填写 -->
  <div class="flexmar">
    <!-- <headbox
      title="快递投保"
      bgColor="#fff"
      isborder="true"
      righturl="claim"
      @saveform="saveformFun"
    ></headbox> -->

    <headbox
      title="快递投保"
      bgColor="#fff"
      isborder="true"
      righturl="claim"
      :isbackperson="true"
    ></headbox>
       <!-- tab标签页 -->
    <van-tabs v-model="activeName" sticky  color="#FF2D2E" title-active-color="#FF2D2E">
       <van-tab title="下保单" name="a" >
             <!-- 主体结构组件 -->
    <toubao></toubao>
       </van-tab>
       <van-tab title="查保单" name="b">
        <checkClaim></checkClaim>
       </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import toubao from './toubao.vue'
import headbox from "@/components/head.vue";
import checkClaim from './index.vue'

export default{
  components:{
      toubao,
      headbox,
      checkClaim
  },
    created(){

    },
    mounted() {
      this.activeName = this.$route.query.active?this.$route.query.active:'a'
    },
    data() {
    return {
      // v-model activeName 的值为当前标签的 name  默认是a
     activeName: 'a',
    };
  },
}
</script>
<style lang="scss" scoped>
// .van-tabs__wrap {
//     margin-bottom: 20px;
// }

::v-deep{
  .van-tabs{
  background-color:#F3F3F3 ;
   .van-tabs__wrap{
     .van-tabs__nav{
       z-index: 1;
     }
    height: 77px;
    .van-tabs__nav{
    margin-left: 7px;
    .van-tab {
      flex: 0;
      width: 140px !important;
      text-align: center;
      
    }
    .van-tab__text{
      font-size: 30px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 800;
      line-height: 34px;
      width: 200px !important;
    }
    .van-tabs__line {
    position: absolute;
    bottom: 2vw;
    left: 0;
    z-index: 1;
    width: 5.333vw;
    height: 0.6vw;
    border-radius: 0.4vw;
}
    .van-tabs__nav{
      .van-tab {
        width: 48px;
      }
    }
      }
   }
  .van-tabs__content{
  margin-top: 21px;
   }
  
}
}

</style>
import { Component, Vue, Model } from "vue-property-decorator";
import { phone, pas, phoneCode } from "@/utils/rule";
import { getAddress, querySysAddressList, addInsurance } from "@/utils/api";
import headbox from "@/components/head.vue";
import AreaList from "../pickUp/area";
import "vant/lib/popup/style";
import "vant/lib/area/style";

@Component({
  components: {
    headbox
  }
})
export default class BindPhoneModel extends Vue {
  public ceringModel: any = false;
  public isclick: any = false;
  public firstshow: any = false;
  /**
   * 定义当前表单验证
   */
  private formdata: any = {
    // 快递单号
    deliveryoid: "",
    // 重量
    weight_input: "",
    // 投保金额
    money_input: "",
    // 选择的品类
    selectbox: "黄金,huangjin",
    // 收货人地址id
    addressid: ""
  };
  /**
   * 定义当前表单验证
   */
  private loginRules: any = {
    loginAccount: [
      { required: true, trigger: "blur", validator: phone[0].validator }
    ],
    phoneCode: [
      { required: true, trigger: "blur", validator: phoneCode[0].validator }
    ],
    password: [{ required: true, trigger: "blur", validator: pas[0].validator }]
  };
  // 保险费
  public bxfei: any = 0;
  public checkboxbool: any = true;
  public address_show: any = false;
  // 地址插件
  public areaList: any = AreaList;
  public addrCode = "440105";
  // 显示城市弹窗
  public show = false;
  // 选择的地址
  public initCity: any = {};
  // 被投保人信息
  public userdetails: any = "";
  public addressBox: any = "";
  public preAddr_: any = "";
  // 之前保留的数据
  public overdata: any = "";
  // 协议内容
  public isClickrule: any = false;
  public showrule: any = false;
  public xieyineirong: any = "";
  public resdata: any = "";

  // 生命周期
  created() {
    if (this.$route.query.pid) {
      this.formdata.deliveryoid = this.$route.query.pid;
    }
  }
  mounted() {
    const first_showpage = localStorage.getItem("first_page");
    if (!JSON.parse(JSON.stringify(first_showpage))) {
      this.firstshow = true;
      localStorage.setItem("first_page", "true");
    }
    // firstshow
    const address_ = localStorage.getItem("address");
    this.overdata = JSON.parse(localStorage.getItem("formdata")!);
    // 防止跳转 数据情况 (分别运单号-重量-保额)
    if (this.overdata && this.overdata.deliveryoid) {
      this.formdata.deliveryoid = this.overdata.deliveryoid;
    }
    if (this.overdata && this.overdata.weight_input) {
      this.formdata.weight_input = this.overdata.weight_input;
    }
    if (this.overdata && this.overdata.money_input) {
      this.formdata.money_input = this.overdata.money_input;
    }

    // 默认发货信息
    getAddress().then((res:any) => {
      // debugger;
      const datalist = res;
      for (const b in res) {
        if (res[b].defAddr == true) {
          if (!address_) {
            this.preAddr_ = res[b].preAddr;
            this.initCity = res[b];
            // debugger;
          }
        }
      }
    });
    // 获取默认收件人地址id
    querySysAddressList().then((res:any) => {
      console.log('res',res);
      
      this.resdata = res.data;
      // debugger;
      this.resdata.forEach((element:any) => {
        if (element.defAddr) {
          this.addressBox = element.company;
          this.formdata.addressid = element.id;
          // debugger;
          // this.formdata.addressid = element.code;
        }
      });
    });
    // 初始化被投保人
    const userinfo = JSON.parse(localStorage.getItem("userinfo")!);
    if (userinfo) {
      this.userdetails = userinfo;
    }
    // 初始化发货地址
    if (address_) {
      this.initCity = JSON.parse(address_);
    }
  }
  //四舍五入保留2位小数（不够位数，则用0替补）
  keepTwoDecimalFull(num:any) {
    let result = parseFloat(num);
    if (isNaN(result)) {
      alert("传递参数错误，请检查！");
      return false;
    }
    result = Math.round(num * 100) / 100;
    let s_x = result.toString();
    let pos_decimal = s_x.indexOf(".");
    if (pos_decimal < 0) {
      pos_decimal = s_x.length;
      s_x += ".";
    }
    while (s_x.length <= pos_decimal + 2) {
      s_x += "0";
    }
    return s_x;
  }
  // 保险额度
  getbaoxian(val:any) {
    if (val) {
      if (val <= 25000) {
        this.bxfei = 5;
      } else {
        const afterVal = Math.ceil(val / 10000);
        this.bxfei = Number(afterVal * 10000 * 0.0002).toFixed();
        // this.bxfei = this.keepTwoDecimalFull(Number(val*0.0004))
      }
    } else {
      this.bxfei = 0;
    }
  }
  saveformFun() {
    localStorage.setItem("formdata", JSON.stringify(this.formdata));
    this.$router.push({ name: "claim" });
  }
  closealert() {
    this.isclick = false;
    this.ceringModel = false;
  }
  // 投保数据提交
  toubaopost(isreal:any) {
    const form_ = this.formdata;
    if (isreal == "realpost") {
      const postdata = {
        expreceNo: this.formdata.deliveryoid, //快递单号
        name: this.userdetails.realName, //投标人姓名
        identificationCard: this.userdetails.identificationCard, //投保人身份证号码
        receiveAddrId: this.formdata.addressid, //收货人地址id
        sendAddrId: this.initCity.id, //发货人地址id
        goodName: this.formdata.selectbox.split(",")[0], //投保货品种类
        key: this.formdata.selectbox.split(",")[1], //商品key，多选时，已逗号隔开（黄金-huangjin，白银-baiyin，铂金-bojin，钯金-bajin，钻石-zuanshi）
        weight: this.formdata.weight_input, //货品重量
        insuranceMoney: this.formdata.money_input, //投保额度
        insuranceFee: this.bxfei //保险费
      };
      // debugger;
      addInsurance(postdata).then((res:any) => {
        // debugger;
        if (res.code !== 0) {
          if (res.msg.length > 10) {
            this.$toast({
              type: "fail",
              message: res.msg,
              className: "lissk"
            });
          } else {
            this.$toast.fail(res.msg);
          }
          this.isclick = false;
          this.ceringModel = false;
        } else {
          localStorage.removeItem("formdata");
          localStorage.removeItem("first_page");
          this.$router.push({ name: "successpage", query: { type: "toubao" } });
        }
      });
    } else {
      if (form_.deliveryoid == "") {
        this.$toast.fail("快递单号不能为空");
        return false;
      }
      if (form_.weight_input == "") {
        this.$toast.fail("重量不能为空");
        return false;
      }
      if (form_.money_input == "") {
        this.$toast.fail("投保金额不能为空");
        return false;
      }
      if (form_.selectbox == "") {
        this.$toast.fail("选择的品类不能为空");
        return false;
      }
      if (!this.checkboxbool) {
        this.$toast.fail("未同意服务条款");
        return false;
      }
      if (this.isclick) {
        return false;
      }
      this.isclick = true;
      this.ceringModel = true;
    }
  }
  confirmF(picker:any, value:any, index:any) {
    this.show = false;
    this.initCity = picker[0].name + "" + picker[1].name + "" + picker[2].name;
  }
  rulepage(type:any) {
    // if(this.isClickrule){return false}
    // this.isClickrule = true
    // 用户协议类型;注册协议-REGISTER, 货运基本条例-TRANSPORT,附加航空-EXTRA_AIR_INSURANCE, 附加邮保险-EXTRA_EXPRESS_INSURANCE ,用户隐私政策-PRIVACY_POLICY
    // claimeditpost.agreementApi(type).then(res=>{
    //   // 货运基本条例- 航空- 附加邮费
    //   this.xieyineirong = res.content
    //   this.showrule =true
    //   this.isClickrule = false
    // })

    localStorage.setItem("formdata", JSON.stringify(this.formdata));
    this.$router.push({ name: type });
  }
  showPopup() {
    localStorage.setItem("formdata", JSON.stringify(this.formdata));
    this.$router.push({ name: "address", query: { type: "claimedit" } });
    // this.show = true;
  }

  hrefbox() {
    // 跳转填写收货地址
    this.$router.push({ name: "address", query: { type: "pickup" } });
  }
  handleRegister(formName:any) {
    (this.$refs[formName] as any).validate((valid: boolean) => {
      if (valid) {
        alert("submit!");
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
}
